html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  /* min-height: 100%;
	height: auto !important;
	height: 100%; */
  /* overflow: hidden !important; */
}

#search-bar-0.form-control {
  margin-bottom: 5px;
}

#cetak-surat > table {
  page-break-inside: auto;
}

#cetak-surat > table > tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

#cetak-surat > table > tr > thead {
  display: table-header-group;
}

#cetak-surat > table > tr > tfoot {
  display: table-header-group;
}

input:focus {
  /* background-color: yellow !important; */
  border-color: #00ff66 !important;
}

.kiosk-card {
  box-shadow: 0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d;
  background-color: #ffffffc7;
  cursor: pointer;
}

.kiosk-card:hover {
  box-shadow: 0 4px 8px 0 #0000009e, 0 6px 20px 0 #0000009e;
}

.logout-kiosk:hover {
  box-shadow: 0 4px 8px 0 #ffffff, 0 6px 20px 0 #ffffff;
  background-color: brown;
}

#bg-kiosk {
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-image: url("../images/kiosk/cv.png");
  background-position: center;
  background-size: cover;
  /* z-index: -1; */
  /* position: absolute; */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: hidden !important;
}

#bg-login-kiosk {
  background-image: url("../images/kiosk/cv.png");
  background-position: center;
  background-size: cover;
}

#bg-login-digidesa {
  background-image: url("../images/kiosk/cv.png");
  background-position: center;
  background-size: cover;
}

#bg-login-digidesa-new {
  background-image: url("../images/bg-login.png");
  background-position: center;
  background-size: cover;
}
.inputss {
  position:relative;
  /* padding-left: 20px; */
    /* direction: rtl; */
}

.inputs{
padding-left: 30px;
}

.icon {
  position:absolute;
    bottom:11px;
    left:10px;
    color: slategrey;
    font-size: 17px;
}

@media (max-width: 376px) {
 h3 img{
   height: 50px;
 } 
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}

a:focus {
  text-decoration: none !important;
}

input {
  color: black !important;
}

p {
  margin-bottom: 1rem !important;
}

.form-control {
  font-size: 14px;
}

.btn-mt1 {
  margin-top: 10px;
}

.dashboard-over {
  border-radius: 15px;
  border: 1px solid #ccc2c2;
  cursor: pointer;
  background-color: #fff;
  color: #707070;
  text-align: center;
}
.dashboard-over span {
  font-size: 60px;
  text-shadow: 2px 2px 5px black;
}

.dashboard-over:hover {
  background-color: #006400;
  color: white;
}

.pengajuan-selesai {
  padding: 10px;
  border-radius: 20px;
  word-wrap: break-word;
  cursor: pointer;
  background-color: #006400;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengajuan-selesai span {
  font-size: 60px;
}

.pengajuan-selesai:hover {
  background-color: #32cd32;
}

.pengajuan-menunggu-eksekusi {
  border-radius: 20px;
  margin-top: 20px;
  word-wrap: break-word;
  cursor: pointer;
  background-color: #ffa500;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengajuan-menunggu-eksekusi span {
  font-size: 60px;
}

.pengajuan-menunggu-eksekusi:hover {
  background-color: #ffd700;
}

.pengaduan-menunggu-eksekusi {
  border-radius: 20px;
  margin-top: 20px;
  word-wrap: break-word;
  background-color: brown;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengaduan-menunggu-eksekusi span {
  font-size: 60px;
}

.pengaduan-menunggu-eksekusi:hover {
  background-color: red;
}

.catatan-menunggu-eksekusi {
  border-radius: 20px;
  margin-top: 20px;
  word-wrap: break-word;
  cursor: pointer;
  background-color: #006400;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.catatan-menunggu-eksekusi span {
  font-size: 60px;
}

.catatan-menunggu-eksekusi:hover {
  background-color: #32cd32;
}

.verifikasi-menunggu-eksekusi {
  background-color: #ffa500;
  border-radius: 20px;
  margin-top: 20px;
  word-wrap: break-word;
  cursor: pointer;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.verifikasi-menunggu-eksekusi span {
  font-size: 60px;
}

.verifikasi-menunggu-eksekusi:hover {
  background-color: #ffd700;
}

.lingkaran {
  background-color: white;
  margin: 0 auto;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 20px;
  margin-left: 20px;
  
}

#bg-beranda-kiosk {
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-image: url("../images/kiosk/cv.png");
  background-position: center;
  background-size: cover;

  /* display: flex;
  align-items: center; */

  /* width: 100%; */
  /* height: 100vh; */
  /* height: calc(100vw * (9/16)); */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto !important;
}

.bg-beranda-kiosk7 {
  /* width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1; */
  background-color: white;

  display: flex;
  align-items: center;

  /* width: 100%; */
  /* height: 100vh; */
  /* height: calc(100vw * (9/16)); */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto !important;
}

.selamat-datang {
  background-color: #227041;
  margin: 5px;
  padding: 20px;
  border-radius: 20px;
  word-wrap: break-word;
  min-height: 300px;
  color: white;
  text-align: center;
  font-size: 28px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}

@media (max-width: 1000px) {
  .selamat-datang {
    font-size: 25px;
  }
}
@media (max-width: 920px) {
  .selamat-datang {
    font-size: 24px;
  }
}
@media (max-width: 890px) {
  .selamat-datang {
    font-size: 23px;
  }
}
@media (max-width: 860px) {
  .selamat-datang {
    font-size: 22px;
  }
}
@media (max-width: 828px) {
  .selamat-datang {
    font-size: 21px;
  }
}
@media (max-width: 806px) {
  .selamat-datang {
    font-size: 19px;
  }
}

.masuk {
  background-color: #227041;
  display: block;
  padding: 20px;
  border-radius: 20px;
  margin: 5px;
  word-wrap: break-word;
  cursor: pointer;
  min-height: 210px;
  color: white;
  text-align: center;
  font-size: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}
@media (max-width: 890px) {
  .masuk {
    font-size: 20px;
  }
}

.masuk:hover {
  background-color: #f5a402;
}

.keluar {
  background-color: #227041;
  padding: 20px;
  border-radius: 20px;
  margin: 5px;
  word-wrap: break-word;
  cursor: pointer;
  min-height: 210px;
  color: white;
  text-align: center;
  font-size: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}
@media (max-width: 890px) {
  .keluar {
    font-size: 20px;
  }
}

.keluar:hover {
  background-color: #f5a402;
}

.gambar-laptop {
  /* src: url("../images/kiosk/bg-kiosk-beranda.png"); */
  /* background-position: center; */
  background-color: #227041;
  margin: 5px;
  border-radius: 20px;
  word-wrap: break-word;
  min-height: 520px;
  color: white;
  text-align: center;
}

.layanan-baru {
  overflow: auto;
  height: 800px;
  background-color: #f6f7fb;
}

@media (max-width: 767px) {
  .layanan-baru {
    height: 500px;
  }
  .pengajuan-selesai span {
    font-size: 80px;
  }
  .verifikasi-menunggu-eksekusi span {
    font-size: 80px;
  }
  .catatan-menunggu-eksekusi span {
    font-size: 80px;
  }
  .pengaduan-menunggu-eksekusi span {
    font-size: 80px;
  }
  .pengajuan-menunggu-eksekusi span {
    font-size: 80px;
  }
}

@media (min-width: 576px) {
  .row .col-sm-2.btn-mt1 {
    margin-top: 0px;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #kertas_antrian,
  #kertas_antrian * {
    visibility: visible;
    /* width: 80mm; */
    /* height: 80mm; */
  }
  #kertas_antrian {
    position: absolute;
    left: 0;
    top: 0;
  }
}
